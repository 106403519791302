<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss">
body {
  padding: 0;
  margin: 0;
}
#app {
  width: 100%;
  height: 100vh;
  background-color: #FFFFFF;
}
</style>
