import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/reset.css";
import 'vue2-toast/lib/toast.css';
import Toast from 'vue2-toast';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import md5 from 'md5';
Vue.prototype.$md5 = md5;

Vue.use(ElementUI);

Vue.use(Toast);
Vue.config.devtools = false;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
